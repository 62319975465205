<template>
  <div>
    <el-dialog
      :title="title + '控制配置'"
      :visible.sync="showing"
      width="96%"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 大盒子 -->
        <div class="big_box">
          <!-- 左边盒子 -->
          <div class="left_box">
            <div class="header_box">
              <div class="jurisdiction">可选被控设备列表</div>
              <!-- <div class="select" @click="checkAllBtn()">全选</div> -->
            </div>
            <div class="content">
              <el-table
                ref="table"
                :data="mode"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                @select="dialogCheck"
              >
                <el-table-column type="selection"> </el-table-column>
                <el-table-column label="设备类型名称" width="120">
                  <template slot-scope="scope">{{
                    scope.row.deviceTypeName
                  }}</template>
                </el-table-column>
                <el-table-column label="设备别名">
                  <template slot-scope="scope">{{
                    scope.row.deviceName
                  }}</template></el-table-column
                >
                <el-table-column label="位号">
                  <template slot-scope="scope">{{
                    scope.row.placeNo
                  }}</template></el-table-column
                >
                <el-table-column label="设备号">
                  <template slot-scope="scope">{{
                    scope.row.deviceNo
                  }}</template></el-table-column
                >
              </el-table>
            </div>
          </div>
          <div class="change">
            <!-- <el-button type="primary">》》</el-button> -->
            <img src="../../img/right.png" alt="" class="img" @click="right" />

            <img src="../../img/left.png" alt="" class="img" @click="left" />
          </div>
          <!-- 右边盒子 -->
          <div class="right_box">
            <div class="header_box">
              <div class="jurisdiction">已选列表</div>
              <!-- <div class="select" @click="checkAllBtn1()">全选</div> -->
            </div>
            <div class="content">
              <!-- <el-checkbox-group class="bottom" v-model="checkedList">
                <el-checkbox
                  v-for="(item, index2) in choosepower"
                  :label="item.name"
                  :key="index2"
                  @change="chooseCheck"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group> -->
              <el-table
                ref="multipleTable"
                :data="choosepower"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleChoose"
              >
                <el-table-column type="selection"> </el-table-column>
                <el-table-column label="设备类型名称" width="120">
                  <template slot-scope="scope">{{
                    scope.row.deviceTypeName
                  }}</template>
                </el-table-column>
                <el-table-column label="设备别名">
                  <template slot-scope="scope">{{
                    scope.row.deviceName
                  }}</template></el-table-column
                >
                <el-table-column label="位号">
                  <template slot-scope="scope">{{
                    scope.row.placeNo
                  }}</template></el-table-column
                >
                <el-table-column label="设备号">
                  <template slot-scope="scope">{{
                    scope.row.deviceNo
                  }}</template></el-table-column
                >
                <el-table-column label="序号">
                  <template slot-scope="scope">{{
                    scope.row.index
                  }}</template></el-table-column
                >
              </el-table>
            </div>
          </div>
          <div class="set_box">
            <div class="header_box">
              <div class="jurisdiction">
                配置参数
                <span v-if="deviceTypeName != null"
                  >({{ deviceTypeName }})</span
                >
              </div>
              <!-- <div class="select" @click="checkAllBtn()">全选</div> -->
            </div>
            <div class="content">
              <el-table
                ref="multipleTable"
                :data="canshuSet"
                tooltip-effect="dark"
                style="width: 100%"
              >
                <!-- <el-table-column> </el-table-column> -->
                <!-- <el-table-column label="被控设备别名" width="120">
                  <template slot-scope="scope">{{
                    scope.row.deviceTypeName
                  }}</template>
                </el-table-column> -->
                <el-table-column label="参数名称">
                  <template slot-scope="scope">{{
                    scope.row.paramName
                  }}</template></el-table-column
                >
                <el-table-column label="设定值">
                  <template slot-scope="scope">
                    <div v-if="scope.row.values.length == 1">
                      <div
                        v-for="(item, index) in scope.row.values"
                        :key="index"
                      >
                        <el-input
                          v-model="scope.row.valueCode"
                          @input="setInput(scope.row)"
                          placeholder="设定值"
                        ></el-input>
                      </div>
                    </div>
                    <div v-if="scope.row.values.length > 1">
                      <el-select
                        class="text"
                        style="width: 100%"
                        @change="setSelect(scope.row)"
                        v-model="scope.row.valueCode"
                        placeholder="请选择房间状态"
                      >
                        <el-option
                          v-for="item in scope.row.values"
                          :key="item.valueCode"
                          :label="item.valueDesc"
                          :value="item.valueCode"
                        >
                        </el-option>
                      </el-select>
                    </div> </template
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="saveBox">
          <el-button type="primary" @click="save" class="addIdType"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="参数配置确定"
      :visible.sync="setVisible"
      width="30%"
      class="setSure"
    >
      <el-table :data="dest" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="被控设备别名">
          <template slot-scope="scope">{{
            scope.row.deviceTypeName
          }}</template></el-table-column
        >
        <el-table-column label="参数">
          <template slot-scope="scope">
            <div class="paramName">
              <div v-for="(item, index) in scope.row.list" :key="index">
                {{ item.paramName }}:{{ item.valueDesc }}
              </div>
            </div>
          </template></el-table-column
        >
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setVisible = false" class="setFalse"
          >取 消</el-button
        >
        <el-button type="primary" @click="setSure">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      //表单
      formInline: {
        arStatus: 0,
        arModule: 1,
        arSpeed: 1,
        arTemperature: "26",
      },
      hotelList: [],
      roomStatusList: [],
      checkedList: [],
      firstList: [],
      canshuSet: [],
      roomType: null,
      controlDid: null,
      deviceTypeName: null,
      totelSetList: [],
      dest: [],
      mode: [
        {
          code: 0,
          name: 1,
        },
        {
          code: 1,
          name: 2,
        },
      ],
      radio: null,
      choosepower: [],
      codeIndex: null,
      operateOrder: null,
      main: {},
      other: {},
      // 选择左侧list
      chooseList: [],
      Params: [],
      setVisible: false,
      //表单验证
      rules: {
        hotelId: [
          {
            required: true,
            message: "请选择酒店",
            trigger: "blur",
          },
        ],
        roomStatus: [
          {
            required: true,
            message: "请输入房间状态",
            trigger: "blur",
          },
        ],
        arTemperature: [
          {
            required: true,
            message: "请输入空调温度",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$parent.getFounderList();
      // this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, infor) {
      this.formInline = {};
      console.log(123, infor);
      this.main = infor;
      this.Params = [];
      this.roomType = infor.roomType;
      this.controlDid = infor.id;
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = Number(hotelInfor.hotelId);
      this.getHotelList();
      this.getRoomStatusList();
      this.getControlled();
      this.choosepower = [];
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
      if (item == "编辑") {
        this.bedisabled = true;
        this.formInline = JSON.parse(JSON.stringify(val));
        this.formInline.arStatus = Number(this.formInline.arStatus);
        this.formInline.arModule = Number(this.formInline.arModule);
        this.formInline.arSpeed = Number(this.formInline.arSpeed);
        // this.formInline.roomStatus = Number(this.formInline.roomStatus);

        console.log(this.formInline);
      }
    },
    getControlled() {
      let parms = {
        roomId: this.$route.query.roomId,
        controlDid: this.controlDid,
      };
      postRequest("canBeControlled", parms).then((res) => {
        if (res.status == 200) {
          this.mode = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    handleSelectionChange(val) {
      this.chooseList = val;
    },

    dialogCheck(selection, row) {
      if (selection.length > 1) {
        this.$refs.table.clearSelection();
      }
    },
    handleChoose(val) {
      this.firstList = val;
      this.getJuris(val);
    },
    getJuris(val) {
      // val.deviceType
      this.canshuSet = [];
      this.other = val[0];
      let length = val[val.length - 1];
      if (length) {
        // val.forEach((item) => {
        this.operateOrder = val[val.length - 1].index;
        let parms = {
          deviceType: val[val.length - 1].deviceType,
        };
        postRequest("/getSysDeviceParams", parms).then((res) => {
          if (res.status == 200) {
            let list = res.data;
            list.forEach((item1) => {
              item1.deviceTypeName = val[val.length - 1].deviceTypeName;
              this.Params.forEach((item3) => {
                if (
                  item1.deviceTypeName == item3.deviceTypeName &&
                  item1.paramCode == item3.paramCode
                ) {
                  console.log(item3);
                  this.canshuSet.push(item3);
                }
              });
            });
            // this.canshuSet = list;
            this.deviceTypeName = list[0].deviceTypeName;
            console.log(this.canshuSet);
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              type: "warning",
            });
          }
        });
        // });
      } else {
        this.deviceTypeName = null;
      }
    },
    save() {
      let setList = [];
      this.Params.forEach((item) => {
        this.choosepower.forEach((item1) => {
          if (
            item.deviceTypeName == item1.deviceTypeName &&
            item.valueCode != null
          ) {
            setList.push(item);
          }
        });
        console.log(item);
      });
      if (setList.length == 0) {
        this.$message({
          duration: 5000,
          message: "请配置参数",
          type: "warning",
        });
        return;
      }
      // console.log(setList);
      this.totelSetList = setList;
      this.totelSetList.forEach((item) => {
        if (item.values.length == 1) {
          item.valueDesc = item.valueCode;
        } else {
          item.values.forEach((element) => {
            if (item.valueCode == element.valueCode) {
              item.valueDesc = element.valueDesc;
            }
          });
        }
      });
      console.log(777, this.totelSetList);
      let dest1 = [];
      let map = this.totelSetList;
      let list1 = this.totelSetList;
      let list2 = [];
      let list3 = [];
      // return;
      list1.forEach((item) => {
        dest1.push(item.deviceTypeName);
      });
      //第一次遍历数组
      dest1 = [...new Set(dest1)];
      console.log(dest1);
      // return
      map.forEach((item1) => {
        dest1.forEach((item2) => {
          if (item1.deviceTypeName == item2) {
            list2.push(item1);
          }
        });
      });
      dest1.forEach((item) => {
        list3.push({
          deviceTypeName: item,
        });
      });

      // list2.forEach(())
      console.log(2, list2);
      console.log(3, list3);
      let allLst = [];
      list3.forEach((item) => {
        item.list = [];
        list2.forEach((item2) => {
          if (item.deviceTypeName == item2.deviceTypeName) {
            item.list.push(item2);
          }
        });
      });
      console.log(7755, list3);
      this.dest = list3;
      this.setVisible = true;
      return;
    },
    setSure() {
      let list = [];
      this.totelSetList.forEach((item) => {
        let parms = [
          {
            paramName: item.paramName,
            paramCode: item.paramCode,
            valueCode: item.valueCode,
            valueDesc: item.valueDesc, // String  值说明
          },
        ];
        list.push({ paramList: parms, deviceTypeName: item.deviceTypeName });
      });
      list.forEach((item2) => {
        item2.roomId = this.$route.query.roomId;
        item2.controlDevice = this.main.id;
        item2.controlNameId = this.main.deviceNameId;
        item2.roomType = this.main.roomType;
        item2.controlDid = this.main.deviceType;
        this.choosepower.forEach((item3) => {
          if (item3.deviceTypeName == item2.deviceTypeName) {
            console.log(333, item3);
            item2.controlledDevice = item3.id;
            item2.controlledNameId = item3.deviceNameId;
            item2.controlledDid = item3.deviceType;
            item2.operateOrder = item3.index;
          }
          console.log(item3);
        });
      });
      console.log(555, list);
      postRequest("insertRoomDeviceControl", list).then((res) => {
        // this.$nextTick(() => {
        //   loadingInstance.close();
        // });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "设置成功！",
            center: true,
            type: "success",
          });
          this.setVisible = false;
          this.showing = false;
          // this.hide();
          // this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
      // this.totelSetList;
    },
    right() {
      if (this.chooseList.length > 0) {
        this.choosepower = this.choosepower.concat(this.chooseList);
        this.choosepower.forEach((item, index) => {
          item.index = index + 1;
        });
        this.getAllSet(this.choosepower);

        // localStorage.setItem("set", JSON.stringify(this.choosepower));
        // let set = JSON.parse(window.localStorage.getItem("set"));
        // console.log(set);
        this.mode.forEach((item, index) => {
          this.chooseList.forEach((item1) => {
            if (item.id == item1.id) {
              this.mode.splice(index, this.chooseList.length);
            }
          });
        });
        this.chooseList = [];
        this.deviceTypeName = null;
      }
    },
    left() {
      if (this.firstList.length > 0) {
        this.mode = this.mode.concat(this.firstList);
        this.choosepower.forEach((item, index) => {
          this.firstList.forEach((item1) => {
            if (item.id == item1.id) {
              this.choosepower.splice(index, this.firstList.length);
            }
          });
        });
        this.getAllSet(this.choosepower);
        // localStorage.setItem("set", JSON.stringify(this.choosepower));
        // let set = JSON.parse(window.localStorage.getItem("set"));
        // console.log(set);
        this.firstList = [];
        this.deviceTypeName = null;
      }

      // console.log(111,this.firstList)
    },
    getAllSet(list) {
      // this.Params = [];
      localStorage.setItem("set", JSON.stringify(list));
      let set = JSON.parse(window.localStorage.getItem("set"));
      console.log(2323, set);
      set.forEach((item) => {
        let parms = {
          deviceType: item.deviceType,
        };
        postRequest("/getSysDeviceParams", parms).then((res) => {
          if (res.status == 200) {
            res.data.forEach((item1) => {
              // console.log(2323, item);
              item1.deviceTypeName = item.deviceTypeName;
              item1.valueCode = null;
              item1.valueDesc = null;
            });
            console.log(11, res.data);
            console.log(22, this.Params);
            // return;
            this.Params = this.Params.concat(res.data);
            for (let i = 0; i < this.Params.length - 1; i++) {
              for (let j = i + 1; j < this.Params.length; j++) {
                if (
                  this.Params[i].deviceTypeName ==
                    this.Params[j].deviceTypeName &&
                  this.Params[i].paramCode == this.Params[j].paramCode
                ) {
                  this.Params.splice(j, 1);
                  j--;
                }
              }
            }
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              type: "warning",
            });
          }
        });
      });
    },
    setInput(row) {
      this.Params.forEach((item, index) => {
        if (
          item.deviceTypeName == row.deviceTypeName &&
          item.paramCode == row.paramCode
        ) {
          this.Params.splice(index, 1, row);
        }
      });
      console.log(4321, this.Params);
    },
    setSelect(row) {
      this.Params.forEach((item, index) => {
        if (
          item.deviceTypeName == row.deviceTypeName &&
          item.paramCode == row.paramCode
        ) {
          this.Params.splice(index, 1, row);
        }
      });
      console.log(4321, this.Params);
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 80px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
  text-align: center;
  margin-left: 0 !important;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button:hover {
  transform: scale(1.1);
}
.big_box {
  display: flex;
  justify-content: space-between;
  height: 450px;
}
.left_box {
  width: 30%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  position: relative;
}
.left_box ::-webkit-scrollbar {
  width: 0 !important;
}

.left_box::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.right_box ::-webkit-scrollbar {
  width: 0 !important;
}

.right_box::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.right_box {
  width: 35%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  position: relative;
}
.set_box {
  width: 25%;
  height: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow-x: hidden;
  overflow-y: scroll;

  position: relative;
}
.header_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 11px 16px; */
  padding: 0 10px;
  position: absolute;
  width: 100%;
  height: 44px;
  background: #fafafa;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-radius: 3px 3px 0px 0px;
  /* position: fixed; */
  z-index: 1000;
}
.jurisdiction {
  /* width: 64px; */
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.select {
  width: 28px;
  height: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #387dff;
  cursor: pointer;
}
.select:hover {
  transform: scale(1.1);
}
.bottom {
  margin-left: 16px;
  margin-top: 13px;
  display: flex;
  flex-direction: column;
}
.el-checkbox + .el-checkbox {
  margin-left: 0 !important;
}
.Horizontal {
  width: 97%;
  height: 1px;
  background: #e8ecef;
  border-radius: 0px;
  margin: 0 auto;
  margin-top: 32px;
}
.content {
  margin-top: 50px;
}
.spname {
  /* margin-left: 10px; */
  cursor: pointer;
  display: inline-block;
}
.change {
  width: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img {
  height: 30px;
  margin-bottom: 10px;
  width: 50px;
  cursor: pointer;
}
.el-radio-group {
  display: flex;
  flex-direction: column;
}
>>> .el-table__header-wrapper .el-checkbox {
  display: none;
}
.saveBox {
  position: relative;
  height: 50px;
}
.addIdType {
  position: absolute;
  right: 0;
}
.setSave {
  position: absolute;
  right: 0;
  bottom: 10px;
}
>>> .setSure {
  z-index: 99999 !important;
}
.el-dialog__body {
  position: relative;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
.setFalse {
  color: #387dff;
  background: #d7e4fc;
  margin-right: 10px;
}
.paramName {
  display: flex;
}
.paramName > div {
  margin-right: 20px;
}
</style>